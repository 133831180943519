<template>
    <div class="contact-form">
			<h2 class="mb-4 text-4xl font-bold capitalize">{{ t('contactForm.title') }}</h2>
      <form @submit.prevent="handleSubmit">
        <div>
          <label for="name">{{ $t('contactForm.name') }}</label>
          <input v-model="form.name" type="text" id="name" required />
        </div>
        <div>
          <label for="email">{{ $t('contactForm.email') }}</label>
          <input v-model="form.email" type="email" id="email" required />
        </div>
        <div>
          <label for="message">{{ $t('contactForm.message') }}</label>
          <textarea v-model="form.message" id="message" rows="4" required></textarea>
        </div>
        <button type="submit" class="px-6 py-2 mr-8 text-white align-middle bg-black border-2 border-black border-solid hover:dark:border-white dark:text-black dark:text-balance dark:bg-white hover:bg-white hover:dark:bg-black hover:text-black hover:dark:text-white">{{ $t('contactForm.send') }}</button>
        <button type="button" @click="toggleForm" class="px-6 py-2 mr-4 text-black align-middle bg-white border-2 border-black border-solid dark:border-white dar:border-white hover:dark:border-black dark:text-white dark:bg-black hover:bg-white hover:dark:bg-black hover:text-black hover:dark:text-white">{{ $t('contactForm.cancel') }}</button>
			</form>
      <p v-if="formStatus" :class="formStatus.type">{{ formStatus.message }}</p>
    </div>
</template>
  
<script setup>
  import { ref } from 'vue';
  import { useI18n } from 'vue-i18n'
	import { defineEmits } from 'vue';
  import { useReCaptcha } from 'vue-recaptcha-v3';

  const { t } = useI18n();

  const { executeRecaptcha } = useReCaptcha();
	
	const emit = defineEmits(['toggle-form']);

	const toggleForm = () => {
		emit('toggle-form');
	};
		
  const form = ref({
    name: '',
    email: '',
    message: ''
  });

  const formStatus = ref(null);
  
  const handleSubmit = async () => {
    const token = await executeRecaptcha('contact_form');
    try {
      const response = await fetch(import.meta.env.VITE_API_URL + '/api/mailer/send', {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            to: import.meta.env.VITE_DEFAULT_EMAIL,
            from: import.meta.env.VITE_SENDER_EMAIL,
            subject: 'New contact on Portfolio',
            message: 'Name: ' + form.value.name + ' \n ' + 'Email: ' + form.value.email + ' \n ' + form.value.message,
            token: token
          }),
      });
      if (response.ok) {
          formStatus.value = {
            type: 'success',
            message: t('contactForm.success'),
          };
          form.value.name = '';
          form.value.email = '';
          form.value.message = '';
        } else {
          throw new Error('Une erreur est survenue.');
        }
      } catch (error) {
        console.log(error)
        formStatus.value = {
          type: 'error',
          message: t('contactForm.error'),
        };
      }
  };
</script>
  
<style scoped>
  .contact-form {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
  }

	.contact-form button::first-letter {
  	text-transform: uppercase;
	}
  
  .contact-form label {
    display: block;
    margin-bottom: 8px;
  }

	.contact-form label::first-letter {
  	text-transform: uppercase;
	}
  
  .contact-form input,
  .contact-form textarea {
    width: 100%;
    padding: 8px;
    margin-bottom: 16px;
		border: 1px solid black;
  }
  
  .contact-form p {
    text-align: center;
    margin-top: 16px;
  }

	.contact-form p::first-letter {
  	text-transform: uppercase;
	}
  
  .contact-form p.success {
    color: green;
  }
  
  .contact-form p.error {
    color: red;
  }
</style>
