import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../views/HomePage.vue'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/:pathMatch(.*)*',
      component: () => import('../views/errors/Error404Page.vue')
    },
    {
      path: '/',
      name: 'home',
      component: HomePage
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('../views/AboutPage.vue')
    },
    {
      path: '/projects',
      name: 'projects',
      component: () => import('../views/ProjectsPage.vue')
    },
    {
      path: '/articles',
      name: 'articles',
      component: () => import('../views/ArticlesPage.vue')
    },
    {
      path: '/test-sentry',
      name: 'TestSentry',
      component: () => import('../views/TestSentry.vue')
    },
    {
      name: 'error403',
      path: '/error/403',
      component: () => import('@/views/errors/Error403Page.vue')
    },
    {
      name: 'error404',
      path: '/error/404',
      component: () => import('@/views/errors/Error404Page.vue')
    },
    {
      name: 'error500',
      path: '/error/500',
      component: () => import('@/views/errors/Error500Page.vue')
    }
  ]
})

export default router
