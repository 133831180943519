<script setup lang="ts">
import { useCustomizerStore } from '@/stores/customizer'

const customizerStore = useCustomizerStore()
const getMoon = () => {
      return new URL(`../assets/images/svgs/moon.svg`, import.meta.url).href
}
const getSun = () => {
      return new URL(`../assets/images/svgs/sun.svg`, import.meta.url).href
}
</script>

<template>
  <div class="relative mr-2 group" to="/">
    <img
      v-if="customizerStore.darkMode"
      :src="getMoon()"
      @click="customizerStore.setDarkMode"
      class="cursor-pointer w-9"
    />
    <img
      v-else
      :src="getSun()"
      @click="customizerStore.setDarkMode"
      class="cursor-pointer w-9"
    />
  </div>
</template>

<style lang="scss" scoped></style>
