<script setup lang="ts">
import ArrowUp from '@/assets/images/svgs/arrow-up.svg'
import { ref, onMounted, onUnmounted } from 'vue'

const showArrow = ref(false)

const handleScroll = () => {
  showArrow.value = window.scrollY > 400
}

const scrollTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
}

onMounted(() => {
  window.addEventListener('scroll', handleScroll)
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
})
</script>

<template>
  <transition name="fade">
    <ArrowUp
      v-if="showArrow"
      @click="scrollTop"
      class="fixed w-10 h-10 p-3 text-white transition-opacity duration-300 bg-black rounded-full shadow-md cursor-pointer dark:bg-white dark:text-black bottom-10 right-10"
    ></ArrowUp>
  </transition>
</template>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
