<script setup lang="ts">
import ExternalLink from '@/assets/images/svgs/external-link.svg'
import AnimatedText from '@/components/AnimatedText.vue'
import { useI18n } from 'vue-i18n'
import ContactForm from '@/components/ContactForm.vue'

const { t } = useI18n()

import { ref } from 'vue';
const isFormVisible = ref(false);

const toggleForm = () => {
  isFormVisible.value = !isFormVisible.value
};

const cvUrl = './CV-EddyGangloff-EN-Full.pdf';

</script>

<template>
  <main class="flex items-center w-full text-black dark:text-white">
    <div class="flex flex-col items-center justify-between w-full md:flex-row">
      <div class="flex content-center justify-center md:w-1/2 h-1/2 md:h-full">
        <img src="@/assets/images/robot-1.png" alt="" class="h-[350px] md:h-auto md:w-[80%]" />
      </div>
      <div class="px-4 md:px-16 md:w-1/2">
        <ContactForm v-if="isFormVisible" @toggle-form="toggleForm"></ContactForm>
        <div v-else>
          <AnimatedText :text="t('homePage.title')"></AnimatedText>
          <p class="my-8 text-base font-medium text-justify">
            {{ t('homePage.text') }}
          </p>
          <div class="flex">
            <a :href="cvUrl" download>
              <button
                class="px-6 py-2 mr-8 text-white align-middle bg-black border-2 border-black border-solid hover:dark:border-white dark:text-black dark:text-balance dark:bg-white hover:bg-white hover:dark:bg-black hover:text-black hover:dark:text-white"
              >
                <span class="inline-block capitalize">{{ t('button.resume') }}</span>
                <ExternalLink class="inline-block ml-2 text-xl align-middle"></ExternalLink>
              </button>
            </a>
            <button
              @click="toggleForm"
              class="px-6 py-2 mr-4 text-black align-middle bg-white border-2 border-black border-solid dark:border-white dar:border-white hover:dark:border-black dark:text-white dark:bg-black hover:bg-white hover:dark:bg-black hover:text-black hover:dark:text-white"
            >
              <span class="inline-block capitalize">{{ t('button.contact') }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
