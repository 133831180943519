import en from './en.json'
import fr from './fr.json'
import th from './th.json'

export const defaultLocale: keyof typeof languages = 'en'

export const languages = {
  en,
  fr,
  th
} as const

// Exportez un type pour les clés de langue
export type Locale = keyof typeof languages
