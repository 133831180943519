import './assets/main.scss'
import * as Sentry from "@sentry/vue";
import { createI18n } from 'vue-i18n'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import clickOutside from './directives/v-click-outside'
import { VueReCaptcha } from 'vue-recaptcha-v3';

import App from './App.vue'
import router from './router'

const app = createApp(App)

/** Sentry only on production */
const isProductionDomain = window.location.hostname === 'eddygangloff.com';
if (isProductionDomain) {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: import.meta.env.NODE_ENV
  });
}

/** I18n  */
import { languages } from './i18n/index.js'
import { defaultLocale } from './i18n/index.js'
const messages = Object.assign(languages)
const i18n = createI18n({
  legacy: false,
  locale: defaultLocale,
  fallbackLocale: 'en',
  messages
})
app.use(i18n)

/** Pinia  */
app.use(createPinia())
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
app.use(pinia)

/** Router */
app.use(router)

/** Directives */
app.directive('click-outside', clickOutside)

/** ReCaptcha V3*/
app.use(VueReCaptcha, {
  siteKey: import.meta.env.VITE_RECAPTCHA_SITE_KEY,
  loaderOptions: {
    useRecaptchaNet: true,
  },
});

app.mount('#app')
