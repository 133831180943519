<script setup lang="ts">
  import { computed } from 'vue';
import { useI18n } from 'vue-i18n'
  const { t } = useI18n()
  const currentYear = computed(() =>{
      return new Date().getFullYear();
  })
</script>

<template>
  <footer class="w-full py-8 mt-12 border-t-2 border-black border-solid dark:border-white">
    <div
      class="container flex flex-col items-center justify-between mx-auto text-black md:flex-row dark:text-white"
    >
      <div class="px-12 mb-4 capitalize">{{ currentYear }} @ {{ t('footer.allRightReserved') }}</div>
      <div class="px-4 mb-4"></div>
      <div class="px-12 mb-4">Eddy Gangloff</div>
    </div>
  </footer>
</template>

<style lang="scss" scoped></style>
