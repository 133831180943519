<script setup lang="ts">
import { useRouter } from 'vue-router'

const router = useRouter()
</script>

<template>
  <div class="flex items-center justify-center mt-2">
    <Motion
      class="flex items-center justify-center w-16 h-16 text-2xl font-bold text-white bg-black rounded-full cursor-pointer dark:text-black dark:bg-white"
      :hover="{
        backgroundColor: [
          'red',
          'yellow',
          'blue',
          'indigo',
          'violet',
          'cyan',
          'magenta',
          'gold',
          'teal',
          'coral'
        ],
        transition: { duration: 4, repeat: Infinity }
      }"
    >
      <span @click="router.push('/')" class="no-underline text-inherit">EG</span>
    </Motion>
  </div>
</template>

<style lang="scss" scoped></style>
