<script setup lang="ts">
const props = defineProps({
  text: String
})
</script>

<template>
  <Motion
    class="flex items-center justify-center text-2xl font-bold text-black cursor-pointer dark:text-white"
    :initial="{ opacity: 0, y: 20 }"
    :animate="{ opacity: 1, y: 0 }"
    :transition="{ duration: 0.5 }"
  >
    <h1 class="text-6xl font-bold capitalize">{{ props.text }}</h1>
  </Motion>
</template>

<style lang="scss" scoped></style>
