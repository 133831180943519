import { defineStore } from 'pinia'

interface State {
  darkMode: boolean
}

export const useCustomizerStore = defineStore('customizer', {
  state: (): State => {
    return {
      darkMode: false
    }
  },
  actions: {
    async setDarkMode() {
      if (this.darkMode) {
        this.darkMode = false
        document.documentElement.classList.remove('dark')
      } else {
        this.darkMode = true
        document.documentElement.classList.add('dark')
      }
    }
  },
  persist: true
})
