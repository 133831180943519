<template>
  <div v-click-outside="closeDropDown" class="relative inline-block text-left">
    <button
      @click="toggleDropdown"
      class="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 rounded-md hover:bg-gray-100 hover:dark:bg-slate-800 focus:outline-none"
      id="dropdownLanguage"
      aria-haspopup="true"
      aria-expanded="true"
    >
      <img :src="getFlag(locale)" alt="Language flag" class="w-5 h-5" />
    </button>

    <div
      v-if="dropdownOpen"
      class="absolute right-0 z-50 mt-2 origin-top-right bg-white rounded-md shadow-lg dark:bg-black w-36 ring-1 ring-black dark:ring-slate-500 ring-opacity-5 focus:outline-none"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="dropdownLanguage"
    >
      <div class="py-1" role="none">
        <div
          v-for="(lang, i) in langs"
          :key="`Lang${i}`"
          @click="setLocale(lang.locale)"
          class="flex items-center px-4 py-2 text-sm text-black cursor-pointer hover:bg-gray-100 hover:dark:bg-slate-800 dark:text-white"
          role="menuitem"
        >
          <img :src="getFlag(lang.locale)" alt="Language flag" class="w-5 h-5 mr-3" />
          {{ lang.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useLocaleStore } from '@/stores/locale'
import { useI18n } from 'vue-i18n'

export default {
  name: 'LocaleChanger',
  data() {
    return {
      dropdownOpen: false,
      langs: [
        {
          locale: 'en',
          name: 'English'
        },
        {
          locale: 'fr',
          name: 'Français'
        },
        {
          locale: 'th',
          name: 'ไทย'
        }
      ]
    }
  },
  setup() {
    const { locale } = useI18n()
    const localeStore = useLocaleStore()
    return {
      locale,
      localeStore
    }
  },
  created() {
    this.locale = this.localeStore.locale
  },
  methods: {
    getFlag(locale: string) {
      return new URL(`../assets/images/flags/flag-${locale}.jpg`, import.meta.url).href
    },
    setLocale(locale: string) {
      this.localeStore.$patch({
        locale: locale
      })
      this.locale = locale
      this.dropdownOpen = false
    },
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen
    },
    closeDropDown() {
      this.dropdownOpen = false
    }
  }
}
</script>

<style lang="scss" scoped></style>
