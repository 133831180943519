import { defineStore } from 'pinia'

export const useLocaleStore = defineStore('locale', {
  state: () => {
    return {
      locale: 'en'
    }
  },

  getters: {},

  actions: {},

  persist: true
})
